import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Tout = makeShortcode("Tout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Oracles`}</h1>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/illustrations/Squid-3.png",
          "alt": "Oracles"
        }}></img></p>
      <Box mdxType="Box">
        <h2>{`Oracles makes on-chain and off-chain data available to smart-contracts`}</h2>
      </Box>
    </InfoBlock>
    <p>{`The Maker Protocol uses oracles enable the use of price data of various assets to determine a number of important things like when to liquidate a Vault or how much Dai a given Vault can generate.`}</p>
    <p>{`MakerDAO's oracles receive data from a number of independent feeds that consist of individuals and organizations. Each oracle corresponds to a single asset and its reference price.`}</p>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h2>{`How it Works`}</h2>
        <p>{`Learn about how the oracle system works.`}</p>
        <p><a parentName="p" {...{
            "href": "/learn/Oracles/how-it-works/"
          }}>{`How it Works`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h2>{`Security`}</h2>
        <p>{`Learn about oracle system is made secure.`}</p>
        <p><a parentName="p" {...{
            "href": "/learn/Oracles/security/"
          }}>{`Security`}</a></p>
      </Box>
    </Tout>
    <p>{`Please visit the `}<a parentName="p" {...{
        "href": "https://docs.makerdao.com/smart-contract-modules/oracle-module"
      }}>{`Oracle Module`}</a>{` section for technical `}<a parentName="p" {...{
        "href": "https://docs.makerdao.com/"
      }}>{`Documentation`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      